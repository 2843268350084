import React from 'react';
import Swiper from 'react-id-swiper';
import VisibilitySensor from 'react-visibility-sensor';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {FormattedMessage} from 'react-intl';

import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import 'swiper/css/swiper.css';
import './App.css'
import Particles from 'react-particles-js';


library.add(fab, fas)




class App extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            swiper: null,
        };

    }

    componentDidUpdate() {
        // let me = this
        if (this.state.swiper !== null) {
            console.log(this.state.swiper);

            this.state.swiper.allowTouchMove = false
            // this.state.swiper.on('touchMove', function (e) {
            //     if (e.target.tagName === "P") {
            //         me.state.swiper.touchEventsTarget = 'wrapper'
            //
            //         //
            //         console.log("p");
            //     }
            // });
        }
        for (const elementsByClassNameElement of document.getElementsByClassName('inner-scroll')) {
            elementsByClassNameElement.addEventListener('touchmove', function(e){e.stopPropagation()}, false);
        }

    }

    //
    // clicchete = () =>{
    //     setTimeout(function(){
    //         // This hides the address bar:
    //         window.scrollTo(0, 0);
    //         window.scrollTo(0, 10);
    //         // alert("dd")
    //     }, 200);
    // }

    onChange(isVisible, elem) {
        // console.log(elem)

        if (isVisible) {
            this.state.swiper.mousewheel.enable()
            document.getElementById(elem).style.opacity = 1
            setTimeout(function () {
                let hones = document.getElementById(elem).childNodes
                for (let i = 0; i < hones.length; i++) {
                    hones.item(i).style.left = "10px"
                }

            }, 500);
        } else {
            document.getElementById(elem).style.opacity = 0
        }
    }


    render() {

        const params = {
            direction: 'vertical',
            slidesPerView: 1,
            spaceBetween: 0,
            mousewheel: true,

            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<a class="' + className + '"/>';
                }
            },

        }



        return (
            <div style={{height: "100%"}}>
                <Swiper {...params} getSwiper={swiper => this.setState({swiper: swiper})} style={{
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundImage: `url('images/bgNight.jpg')`
                }}>
                    <div className={"slide"} style={{
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundImage: `url('images/bgNight.jpg')`
                    }}>

                        <Particles params={{
                        "particles": {
                            "number": {
                                "value": 109,
                                "density": {
                                    "enable": true,
                                    "value_area": 800
                                }
                            },
                            "color": {
                                "value": "#ffffff"
                            },
                            "opacity": {
                                "value": 0.5,
                                "random": false
                            },
                            "size": {
                                "value": 3,
                                "random": true
                            },
                            "line_linked": {
                                "enable": true,
                                "distance": 150,
                                "color": "#ffffff",
                                "opacity": 0.2,
                                "width": 1
                            },
                            "move": {
                                "enable": true,
                                "speed": 1,
                                "direction": "none",
                                "random": false,
                                "straight": false,
                                "out_mode": "out",
                                "bounce": false,
                                "attract": {
                                    "enable": false,
                                    "rotateX": 600,
                                    "rotateY": 1200
                                }
                            }
                        },
                        "interactivity": {
                            "detect_on": "canvas",
                            "events": {
                                "onhover": {
                                    "enable": false,
                                    "mode": "repulse"
                                },
                                "onclick": {
                                    "enable": true,
                                    "mode": "push"
                                },
                                "resize": true
                            }
                        },
                        "retina_detect": true
                    }} />
                        <div className={"homeCard"}>

                            <h1>Giovanni Moretti</h1>
                            <img className={"santino"} src={"images/giovanni_foto.jpg"}/>
                            <h3>
                                <FormattedMessage id="home.title"/>
                            </h3>
                            <div>
                                <h3 style={{fontStyle: "italic"}}>“<FormattedMessage id="home.asimov"/>” </h3>
                                <h4 style={{textAlign: "right"}}>― Isaac Asimov, Foundation</h4>
                            </div>
                        </div>
                    </div>
                    <div className={"slide"}>
                        <div className={"imageContainer"}>
                            <VisibilitySensor onChange={(e) => this.onChange(e, "slide2Col")}>
                                <img className={"centerImage"} src={"images/bio.png"}/>
                            </VisibilitySensor>
                        </div>

                        <div className={"rightCol"} id={"slide2Col"}>
                            <div className={"rightColContent"} >

                                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}><h1> <FormattedMessage id="home.shortbio.title"/></h1><span><a href={"cv.pdf"} title={"Curriculum Vitae"}><FontAwesomeIcon icon={['fas', 'info-circle']} style={{fontSize:"20px"}} /></a> </span></div>

                                <FormattedMessage tagName='p' id="home.shortbio.text"
                                                  values={{
                                                      br: <br /> ,
                                                      circseLink: <a target={"_blank"} href="https://centridiricerca.unicatt.it/circse_index.html">CIRCSE</a>,
                                                      dhLink: <a target={"_blank"} href="https://dh.fbk.eu">Digital Humanities</a>,
                                                      fbkLink: <a target={"_blank"} href="https://www.fbk.eu/it/">Fondazione Bruno Kessler</a>,
                                                      unicattLink: <a target={"_blank"} href="https://www.unicatt.it">Università Cattolica del Sacro Cuore</a>,
                                                      celctLink: <a target={"_blank"} href="https://en.wikipedia.org/wiki/Center_for_the_Evaluation_of_Language_and_Communication_Technologies">CELCT</a>,
                                                      // ibmAward: <a target={"_blank"} href="https://magazine.fbk.eu/en/news/silver-medal-for-giovanni-moretti-at-ibm-watson-services-challenge/">IBM award</a>,
                                                      // pagansAward: <a target={"_blank"} href="http://dhawards.org/dhawards2015/results/">DH awards results</a>,
                                                      // pagans2Award: <a target={"_blank"} href="https://dh.fbk.eu/2016/03/and-the-winner-is-pagans/">Pagans award</a>
                                                  }}/>
                                {/*<p>Since June 2019, I'm a Software Developer of the CIRCSE research center at Università Cattolica del Sacro Cuore.</p>*/}
                                {/*<p>From May 2013 to May 2019, I was the Software Developer of the Digital Humanities joint research project at Fondazione Bruno Kessler.</p>*/}
                                {/*<p>Previously, I worked as administrator of Oracle systems and, from 2007 to 2013, I worked in CELCT being involved in many Human Language Technology projects developing scripts and web interfaces for data acquisition, annotation and result analysis.</p>*/}
                                {/*<p>I’m familiar with most of the more used programming and mark-up languages (e.g. Java, Javascript, C, C++, Perl, Php, VB, HTML5, CSS3) and I have extensive experience in the development of web sites and of both web-based and stand-alone applications. Currently, I'm strengthening my expertise in data visualization applying D3js, Sigmajs, Gephi, Ract, Angular, Electron and Highcharts frameworks to DH projects.</p>*/}
                                <p>Research interests<br/>
                                    Algorithms Data Visualization Programming</p>

                                <h1><FormattedMessage  id="home.shortbio.awards"/></h1>
                                <p>
                                    <ul>
                                        <li> <a target={"_blank"} href="https://magazine.fbk.eu/en/news/silver-medal-for-giovanni-moretti-at-ibm-watson-services-challenge/">IBM award</a></li>
                                        <li> <a target={"_blank"} href="http://dhawards.org/dhawards2015/results/">DH awards results</a> with <a target={"_blank"} href="https://dh.fbk.eu/2016/03/and-the-winner-is-pagans/">Pagans</a></li>
                                    </ul>
                                </p>



                            </div>
                        </div>

                    </div>
                    <div className={"slide"}>
                        <div className={"imageContainer"}>
                            <VisibilitySensor onChange={(e) => this.onChange(e, "slide3Col")}>
                                <img className={"centerImage"} src={"images/code.png"}/>
                            </VisibilitySensor>
                        </div>

                        <div className={"rightCol"} id={"slide3Col"}>
                            <div className={"rightColContent"}>
                                <h1><FormattedMessage id="home.projects.title"/></h1>

                                <p>
                                    <FormattedMessage id="home.projects.work"/>
                                    <ul>
                                        <li><a href={"https://lila-erc.eu/"} target={"_blank"}>LiLa - Erc Project </a></li>
                                    </ul>
                                </p>


                                <p><FormattedMessage id={"home.projects.pastwork"}/>
                                    <ul>
                                        <li><a href={"http://dh.fbk.eu/projects/hatemeter-hate-speech-tool-tackling-anti-muslim-hatred-online"} target={"_blank"}>Hatemeter (Monitoring Platform)</a></li>
                                        <li><a href={"http://dh.fbk.eu/projects/epistolario-national-edition-de-gasperis-letters-digital-format"} target={"_blank"}>Epistolario De Gasperi</a></li>
                                        <li><a href={"http://dh.fbk.eu/projects/alcide-analysis-language-and-content-digital-environment"} target={"_blank"}>ALCIDE</a></li>
                                        <li><a href={"http://dh.fbk.eu/technologies/martin"} target={"_blank"}>Martin</a></li>
                                        <li><a href={"http://dh.fbk.eu/projects/vvv-verbo-visuale-virtuale-la-piattaforma-di-ricerca-interattiva-dellarte-verbo-visuale"} target={"_blank"}>VVV</a></li>
                                        <li><a href={"https://dh.fbk.eu/2016/03/and-the-winner-is-pagans/"} target={"_blank"}>Pagans</a></li>
                                        <li><a href={" http://dh.fbk.eu/projects/madre-mapping-public-discourse-equal-opportunities"} target={"_blank"}>Madre</a></li>
                                        <li><a href={"http://dh.fbk.eu/resources/cat-content-annotation-tool"} target={"_blank"}>CAT</a></li>
                                    </ul>
                                </p>

                                <p><FormattedMessage id={"home.projects.collaboration"}/>
                                    <ul>
                                        <li><a href={"http://tint.fbk.eu"} target={"_blank"}>Tint - Italian NLP Pipeline</a></li>
                                    </ul>
                                </p>

                            </div>
                        </div>

                    </div>
                    <div className={"slide"}>
                        <div className={"imageContainer"}>
                            <VisibilitySensor onChange={(e) => this.onChange(e, "slide4Col")}>
                                <img className={"centerImage"} src={"images/works.png"}/>
                            </VisibilitySensor>
                        </div>

                        <div className={"rightCol"} id={"slide4Col"}>
                            <div className={"rightColContent"}>
                                <h1>Tools</h1>
                                <p><FormattedMessage id={"home.tools.text"}/>
                                <ul>
                                    <li><a href={"https://github.com/dhfbk/KD"} target={"_blank"}>KD - Keyphrase Digger</a></li>
                                    <li><a href={"https://github.com/dhfbk/rambleon-navigator"} target={"_blank"}>Ramble On Navigator</a></li>
                                    <li><a href={"https://github.com/dhfbk/LOD_Navigator"} target={"_blank"}>LOD Navigator</a></li>
                                    <li><a href={"https://dh.fbk.eu/technologies/tn-navigator"} target={"_blank"}>TN Navigator</a></li>
                                    <li><a href={"https://github.com/dhfbk/tint/"} target={"_blank"}>Tint - Italian NLP Pipeline</a></li>

                                </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id={"slideContacts"} className={"slide"}>
                        <div className={"imageContainer"} style={{flexFlow: "column", justifyContent: "flex-start", paddingTop: "50px"}}>
                            <VisibilitySensor>
                                <h1><FormattedMessage id={"home.contacts.title"}/></h1>
                            </VisibilitySensor>
                            <div style={{display: "flex", zIndex: "10"}}>
                                <div className={"iconCircle"}><a href={"https://github.com/johnnymoretti?tab=repositories"} target={"_blank"}> <FontAwesomeIcon icon={['fab', 'github']} style={{color: "#fff"}}/></a></div>
                                <div className={"iconCircle"}><a href={"https://twitter.com/Giova_Moretti"} target={"_blank"}> <FontAwesomeIcon icon={['fab', 'twitter']} style={{color: "#fff"}}/></a></div>
                                <div className={"iconCircle"}><a href={"https://www.linkedin.com/in/giovanni-moretti-701a0733"} target={"_blank"}> <FontAwesomeIcon icon={['fab', 'linkedin-in']} style={{color: "#fff"}}/></a></div>
                                <div className={"iconCircle"}><a href={"https://scholar.google.com/citations?hl=en&user=dJjgR3MAAAAJ"} target={"_blank"}> <FontAwesomeIcon icon={['fas', 'graduation-cap']} style={{color: "#fff"}}/></a></div>
                            </div>
                        </div>


                    </div>
                </Swiper>
            </div>
        );
    }

}


export default App;
