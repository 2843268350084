import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';
import messages_it from "../languages/it.json";
import messages_en from "../languages/en.json";

export const Context = React.createContext()

export {Context as IntlContext }

const local = navigator.language.split(/[-_]/)[0];

let lang;
if (local==="en") {
    lang = messages_en;
} else {
    if (local === "it") {
        lang = messages_it;
    } else {
        lang = messages_en;
    }
}



const Wrapper = (props) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);
    function selectLanguage(e) {
        const newLocale = e.target.value;
        setLocale(newLocale);
        if (newLocale === 'en') {
            setMessages(messages_en);
        } else {
            if (newLocale === 'it'){
                setMessages(messages_it);
            } else {
                setMessages(messages_en);
            }
        }
    }

    return (
        <Context.Provider value = {{locale, selectLanguage}}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
}
export default  Wrapper
