import React, {useContext} from 'react';
import {Context} from "./Wrapper";
import App from "../App";

function LS() {
    const context = useContext(Context);
    return (
        <div style={{height:"100%"}}>
            <select value = {context.locale} onChange={context.selectLanguage} style={{position:"fixed",zIndex:10000,right:"8px"}}>
                <option value= 'en'>English</option>
                <option value= 'it'>Italiano</option>
            </select>
        <App/>
        </div>
    );
}
export default LS;